// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../assets/fonts/NHaasGroteskTXPro-55Rg.woff";
import ___CSS_LOADER_URL_IMPORT_1___ from "../assets/fonts/NHaasGroteskTXPro-56It.woff";
import ___CSS_LOADER_URL_IMPORT_2___ from "../assets/fonts/NHaasGroteskTXPro-75Bd.woff";
import ___CSS_LOADER_URL_IMPORT_3___ from "../assets/fonts/NHaasGroteskTXPro-76BdIt.woff";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: 'neue-regular';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff\");\n  font-style: normal; }\n\n@font-face {\n  font-family: 'neue-italic';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\");\n  font-style: normal; }\n\n@font-face {\n  font-family: 'neue-bold';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff\");\n  font-style: normal; }\n\n@font-face {\n  font-family: 'neue-bold-italic';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\");\n  font-style: normal; }\n", "",{"version":3,"sources":["webpack://src/styles/styles.scss"],"names":[],"mappings":"AAEA;EACE,2BAA2B;EAC3B,2DAAsE;EACtE,kBAAkB,EAAA;;AAGpB;EACE,0BAA0B;EAC1B,2DAAsE;EACtE,kBAAkB,EAAA;;AAGpB;EACE,wBAAwB;EACxB,2DAAsE;EACtE,kBAAkB,EAAA;;AAGpB;EACE,+BAA+B;EAC/B,2DAAwE;EACxE,kBAAkB,EAAA","sourcesContent":["// NEUE HAAS GROTESK TEXT PRO\n\n@font-face {\n  font-family: 'neue-regular';\n  src: url('../assets/fonts/NHaasGroteskTXPro-55Rg.woff') format('woff');\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'neue-italic';\n  src: url('../assets/fonts/NHaasGroteskTXPro-56It.woff') format('woff');\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'neue-bold';\n  src: url('../assets/fonts/NHaasGroteskTXPro-75Bd.woff') format('woff');\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'neue-bold-italic';\n  src: url('../assets/fonts/NHaasGroteskTXPro-76BdIt.woff') format('woff');\n  font-style: normal;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
